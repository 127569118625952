import React from "react"
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import BackgroundImg from 'gatsby-background-image'
import { Link } from 'gatsby'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme =>({
    root: {
        padding: '0 30px',
    },
    hero: {
        // minHeight: '60vh',
        [theme.breakpoints.up('sm')]: {
            minHeight: '60vh',
        },
        
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '60vh',
        },
        // minHeight: '60vh',
        padding: '25px',
        backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    heading2: {
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: '#ffffff',
            width: '5rem',
            height: '.15rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '-2rem',
        }
    },
    paragraphs: {
        marginBottom: '1rem',
        marginTop: '2rem',
    },
    paragraph: {
        marginBottom: '.5rem',
    },
    span: {

    },
    clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    avatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
}));

const LinkBox2 = ({ nodes, stationName, image, language }) => {
    const classes = useStyles();
    const station = nodes.filter(function(node, index) {
        if (node.name === stationName) {
            return node;
        }
    });
    let linkto = '';
    let label = '';
    let title = '';
    let descriptions = [];
    if ('ja' === language) {
        linkto = '/station/'+stationName+'/';
        label = station[0].stationName.ja+'詳細';
        title = station[0].stationName.ja;
        descriptions = station[0].pages[0].ja.descriptions;
    } else if ('en' === language) {
        linkto = '/en/station/'+stationName+'/';
        label = 'Detail of '+station[0].stationName.en;
        title = station[0].stationName.en;
        descriptions = station[0].pages[0].en.descriptions;
    } else if ('zh_hant' === language) {
        linkto = '/zh_hant/station/'+stationName+'/';
        label = station[0].stationName.zh_hant+'詳細';
        title = station[0].stationName.zh_hant;
        descriptions = station[0].pages[0].zh_hant.descriptions;
    } else if ('zh_hans' === language) {
        linkto = '/zh_hans/station/'+stationName+'/';
        label = station[0].stationName.zh_hans+'詳細';
        title = station[0].stationName.zh_hans;
        descriptions = station[0].pages[0].zh_hans.descriptions;
    } else {
        linkto = '/station/'+stationName+'/';
        label = station[0].stationName.ja+'詳細';
        title = station[0].stationName.ja;
        descriptions = station[0].pages[0].ja.descriptions;
    }
    // const linkto = '/station/'+stationName+'/';
    // const label = station[0].pages.ja.stationName+'詳細';
    return (
        <Link to={linkto}>
            <BackgroundImg
                Tag="section"
                fluid={image}
                backgourndColor={`#48bd98`}
                style={{
                    // minHeight: '60vh',
                    // backgroundSize: 'auto',
                }}
            >
                <section className={classes.hero}><div className={classes.wrapper}>
                    <h2><span className={classes.heading2}>{title}</span></h2>
                    <div className={classes.paragraphs}>
                        {
                            descriptions.map((description, i) => (
                                <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                            ))
                        }
                    </div>
                    <Chip
                        avatar={<Avatar className={classes.avatar}><ChevronRightIcon /></Avatar>}
                        label={label}
                        clickable
                        className={classes.clip}
                        // color="primary"
                        // variant="outlined"
                    />
                </div></section>
            </BackgroundImg>
        </Link>
        // <pre>{JSON.stringify(image, null, 4)}</pre>
    );
}

LinkBox2.propTypes = {
    nodes: PropTypes.array,
    stationName: PropTypes.string,
    image: PropTypes.object,
    language: PropTypes.string,
}

export default LinkBox2