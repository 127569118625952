import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import TrainIcon from '@material-ui/icons/Train';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import './card.scss'

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#48bd96",
  },
  clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    clipAvatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
    stationLinkRight: {
      marginLeft: 'auto',
    }
}));

const HotelCardHeian = ({ language }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

  const data = useStaticQuery(graphql`
    {
      hotelsJson(name: {eq: "heian"}) {
        id
        lat
        lon
        prefecture
        city
        address
        zip
        website
        pages {
          zh_hant {
            categories
            descriptions
            hotelName
            language
            nearestStationName
            reservations {
              title
              url
            }
            sections {
              isChild
              paragraphs
              title
              type
              gallery {
                directory
                items {
                  filename
                  title
                }
              }
            }
            overview
            pickup
            access
            address {
              address1
              address2
              city
              prefecture
            }
          }
          zh_hans {
            access
            categories
            address {
              address1
              address2
              city
              prefecture
            }
            descriptions
            hotelName
            language
            nearestStationName
            overview
            pickup
            reservations {
              title
              url
            }
            sections {
              gallery {
                directory
                items {
                  filename
                  title
                }
              }
              isChild
              paragraphs
              title
              type
            }
          }
          ja {
            access
            address {
              address1
              address2
              city
              prefecture
            }
            categories
            descriptions
            hotelName
            language
            nearestStationName
            overview
            pickup
            reservations {
              title
              url
            }
            sections {
              gallery {
                directory
                items {
                  filename
                  title
                }
              }
              isChild
              paragraphs
              title
              type
            }
          }
          en {
            access
            address {
              address1
              address2
              city
              prefecture
            }
            categories
            descriptions
            hotelName
            language
            nearestStationName
            overview
            pickup
            reservations {
              title
              url
            }
            sections {
              gallery {
                directory
                items {
                  filename
                  title
                }
              }
              isChild
              paragraphs
              title
              type
            }
          }
        }
        rakuten {
          hotelPolicyInfo {
            availableCreditCard {
              card
            }
          }
          hotelFacilitiesInfo {
            aboutBath {
              bathQuality
              bathType
              bathBenefits
            }
            hotelRoomNum
            hotelFacilities {
              item
            }
            roomFacilities {
              item
            }
          }
          hotelDetailInfo {
            checkinTime
            checkoutTime
            lastCheckinTime
          }
          hotelBasicInfo {
            latitude
            longitude
            access
            hotelNo
            hotelName
            nearestStation
            parkingInformation
            reviewCount
            reviewAverage
          }
        }
        name
        nearestStationID
        access {
          by
          time
          unit
        }
        prefecture_id
        phoneNumber
        prefecture_name
      }
      hero:file(relativePath: {eq: "hotels/heian/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }

        }
      }
    }
  `)

    const hotel = data.hotelsJson;
    const page = hotel.pages[language];
    const rakuten = hotel.rakuten;
    const imgSrc = data.hero.childImageSharp.fluid.src;

    const categories = page.categories.map(category => category).join(', ');
    const descriptions = page.descriptions.map(description => description);

    let hotelSlug;
    if ('ja' == language) {
        hotelSlug = '/hotel/'+hotel.prefecture_name+'/'+hotel.name+'/';
    } else {
        hotelSlug = '/'+language+'/hotel/'+hotel.prefecture_name+'/'+hotel.name+'/';
    }

    let avatarUnit;
    let clipLavel;
    if ('ja' == language) {
        avatarUnit = <span class="unitJa">分</span>;
        clipLavel = <span>{page.hotelName}詳細</span>;
    } else if ('zh_hant' == language) {
        avatarUnit = <span class="unitZh">分鐘</span>;
        clipLavel = <span>{page.hotelName}詳細</span>;
    } else if ('zh_hans' == language) {
        avatarUnit = <span class="unitZh">分钟</span>;
        clipLavel = <span>{page.hotelName}詳細</span>;
    } else if ('en' == language) {
        avatarUnit = <span class="unitEn">min</span>;
        clipLavel = <span>More {page.hotelName}</span>;
    } else {
        avatarUnit = <span class="unitEn">min</span>;
        clipLavel = <span>More {page.hotelName}</span>;
    }
    let byIcon;
    if ('walk' == hotel.access.by) {
        byIcon = <span class="byIconWalk"><WalkIcon /></span>
    } else if ('car' == hotel.access.by) {
        byIcon = <span class="byIconCar"><CarIcon /></span>
    } else if ('taxi' == hotel.access.by) {
        byIcon = <span class="byIconCar"><CarIcon /></span>
    } else if ('train' == hotel.access.by) {
        byIcon = <span class="byIconTrain"><TrainIcon /></span>
    } else if ('bike' == hotel.access.by) {
        byIcon = <span class="byIconBike"><BikeIcon /></span>
    } else {
        byIcon = <span><WalkIcon /></span>
    }
    const titleContent = <span>{page.hotelName}</span>;
    const subheaderContent = <span>{page.nearestStationName}</span>;

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <div>
                    <Badge badgeContent={avatarUnit} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                        <Avatar aria-label="access" className={classes.avatar}>
                            {hotel.access.time}
                        </Avatar>
                    </Badge>{byIcon}</div>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={titleContent}
                subheader={subheaderContent}
            />
            <CardMedia
                className={classes.media}
                image={imgSrc}
                title={page.hotelName}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {descriptions}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Link to={hotelSlug}><Chip
                    avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                    label={clipLavel}
                    href={hotelSlug}
                    clickable
                    className={classes.clip}
                    // variant="outlined"
                /></Link>
                <IconButton aria-label="inner link" href={hotelSlug} className={classes.stationLinkRight}>
                    <ChevronRightIcon />
                </IconButton>
            </CardActions>
        </Card>
        // <pre>{JSON.stringify(data, null, 4)}</pre>
    );
}

HotelCardHeian.propTypes = {
    language: PropTypes.string,
}

export default HotelCardHeian